import React from 'react';
import styled from 'styled-components';
import { ColumnContainer, Text } from '../dls';
import MerakiHero from './images/meraki_hero.svg'
import OverviewSection from './components/OverviewSection';
import ExploringSection from './components/ExploringSection';
import DesignSection from './components/DesignSection';
import Footer from '../Footer/Footer';

const MerakiPage = () => {
  return (
    <Container>
      <Text textStyle='heading'>
        Meraki Design House
      </Text>
      <img src={MerakiHero} alt='meraki-hero' />
      <OverviewSection />
      <ExploringSection />
      <DesignSection />
      <Footer />
    </Container>
  )
}

export default MerakiPage;

const Container = styled(ColumnContainer)`
  margin-top: 50px;
  width: 100%;

  img {
    margin: 20px 0px;
  }

  background: linear-gradient(180deg, #798C92 0%, rgba(121, 140, 146, 0) 100%);
`