import React, { useEffect } from 'react';
import { Route as ReactRoute } from 'react-router-dom';

const Route = (props) => {
  useEffect(() => {
    document.title = props.title || 'Joyce Tsunekawa';
  }, [props.title]);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <ReactRoute
      name={props.name}
      exact={props.exact}
      path={props.path}
      component={props.component}
    />
  );
};

export default Route;
