import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { ColumnContainer, FlatContainer, Text, color } from "../dls";
import TextLoop from 'react-text-loop';
import Headshot from './_images/joyce_headshot_bnw.png';
import { ChevronDown } from "react-feather";
import { HashLink } from 'react-router-hash-link';
import Aura from './_images/aura_thumb.png';
import Meraki from './_images/meraki_thumb.png';
import Footer from "../Footer/Footer";

const HomePage = () => {
  return (
    <Container>
      <MainContainer justifyContent='flex-end' marginUnset>
        <TextContainer marginUnset>
          <Text textStyle='heading' color='pastelRed'>
            Joyce Tsunekawa
          </Text>
          <Text textStyle='heading'>
            is
          </Text>
          <Text textStyle='heading'>
            a product designer & a {' '}
          <TextLoop springConfig={{ stiffness: 180, damping: 8 }} adjustingSpeed={250} interval={1500} mask>
            <Text textStyle='heading'>
              cellist
            </Text>
            <Text textStyle='heading'>
              disney fanatic
            </Text>
            <Text textStyle='heading'>
              tetris maniac
            </Text>
          </TextLoop>
          </Text>
        </TextContainer>
        <img src={Headshot} alt="headshot" />
      </MainContainer>
      <ArrowContainer>
        <HashLink smooth to="#work">
          <ChevronDown size={40} />
        </HashLink>
      </ArrowContainer>
      <section id='work'>
        <WorkContainer>
          <Text textStyle='heading'>
            Work
          </Text>
          <StyledLink to='/aura_health'>
            <CardContainer>
              <img src={Aura} alt='aura' />
              <CardTextContainer>
                <Text textStyle='title'>
                  Aura Health
                </Text>
                <Text textStyle='paragraph2'>
                  Product Design • Case Study • Internship
                </Text>
              </CardTextContainer>
            </CardContainer>
          </StyledLink>
          <StyledLink to='/meraki'>
            <CardContainer>
              <img src={Meraki} alt='meraki' />
              <CardTextContainer>
                <Text textStyle='title'>
                  Meraki
                </Text>
                <Text textStyle='paragraph2'>
                  Product Design • Case Study • Contract
                </Text>
              </CardTextContainer>
            </CardContainer>
          </StyledLink>
        </WorkContainer>
      </section>
      <Footer />
    </Container>
  )
}

export default HomePage;

const MainContainer = styled(FlatContainer)`
  background: transparent;
  width: 1200px;
  img {
    height: 750px;
    width: 660px;
  }

  ${Text}{
    margin-right: 10px;
  }
`

const TextContainer = styled(FlatContainer)`
  width: 475px;
  margin-top: -75px;
  margin-right: -50px;
`

const Container = styled(ColumnContainer)`
  background-image:
    linear-gradient(360deg, #E9DCC9 0%, rgba(233, 220, 201, 0) 71.15%),
    linear-gradient(180deg, #798C92 0%, rgba(121, 140, 146, 0) 34.81%);
`

const ArrowContainer = styled(FlatContainer)`
  position: relative;

  svg {
    position: absolute;
    left: -375px;
    top: -40px;
    text-decoration: none;
    color: black;

    &:hover {
      color: ${color.pastelRed};
      cursor: pointer;
    }
  }
`

const WorkContainer = styled(ColumnContainer)`
  padding-top: 100px;
  padding-bottom: 50px;
  width: 100%;
`

const CardContainer = styled(FlatContainer)`
  width: 900px;
  height: 330px;
  margin-top: 80px;
  justify-content: space-between;
  padding: 20px 50px;
  border-radius: 5px;
  border: 1px solid ${color.darkBlue};

  &:hover {
    cursor: pointer;
    box-shadow: 0px 4px 20px rgba(31, 24, 82, 0.1);
    ${Text} {
      color: ${color.darkBlue};
    }
  }
`
const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`

const CardTextContainer = styled(ColumnContainer)``