import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Text, color } from '../dls';

const HeaderLink = (props) => {
  return (
    <StyledLink to={props.to}>
      <Text textStyle='menu'>
        {props.text}
      </Text>
    </StyledLink>
  )
}

export default HeaderLink;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  &:hover {
    color: ${color.pastelRed};
  }
`