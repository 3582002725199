import React from 'react';
import styled from 'styled-components';
import { ColumnContainer, FlatContainer, Text } from "../../dls";

const IconBlurb = (props) => {
  const { icon, title, blurb, textStyle } = props;
  return (
    <Container justifyContent='space-between'>
      {icon}
      <TextContainer>
        <Text textStyle={textStyle ? textStyle : 'paragraph1'}>
          {title}
        </Text>
        <Text textStyle='paragraph2'>
          {blurb ? blurb : ''}
        </Text>
      </TextContainer>
    </Container>
  )
}

export default IconBlurb;

const Container = styled(FlatContainer)`
  align-items: flex-start;
  width: 500px;
`

const TextContainer = styled(ColumnContainer)`
  align-items: flex-start;
  width: 404px;

  ${Text}:first-child {
    margin-bottom: 10px;
  }
`