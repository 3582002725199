import React from 'react';
import styled from 'styled-components';
import { ColumnContainer, Text, SectionHead, FlatContainer } from '../../dls';
import AbandonCards from '../images/abandon-cards.svg';


const ExploringSection = () => {
  return (
    <Container>
      <ContentContainer>
        <SectionHead header='exploring meraki' />
        <StandardText textStyle='paragraph1'>
          Why do people abandon carts?
        </StandardText>
        <StandardText textStyle='paragraph2'>
          Ten frequent online shoppers were interviewed to reveal the frustrations and confidences that they experience during their online shopping. These online shoppers included careful critics to spontaneous shoppers who shop from small local shops and corporate businesses. Despite their differences, these were overlapping concerns and priorities when it came to online shopping:
        </StandardText>
        <ImageContainer>
          <img src={AbandonCards} alt='sizes-card' />
        </ImageContainer>
        <ChallengeContainer>
          <StandardText textStyle='paragraph1'>
            Usability test learnings
          </StandardText>
          <StandardText textStyle='paragraph2'>
            Usability tests were conducted on the current site to understand the experience of browsing and purchasing a pair of shoes.
          </StandardText>
          <StandardText textStyle='paragraph2'>
            Friction that cause shoppers to hesitate to purchase:
          </StandardText>
          <ListContainer>
            <ul>
              <li>
                <StandardText textStyle='paragraph2'>
                  Lack of information about the business
                </StandardText>
              </li>
              <li>
                <StandardText textStyle='paragraph2'>
                  Challenge with navigating the site
                </StandardText>
              </li>
              <li>
                <StandardText textStyle='paragraph2'>
                  Confusion with menu wording
                </StandardText>
              </li>
              <li>
                <StandardText textStyle='paragraph2'>
                  Unable to narrow their search
                </StandardText>
              </li>
              <li>
                <StandardText textStyle='paragraph2'>
                  Concerns about finding sizing and shipping/return information
                </StandardText>
              </li>
            </ul>
          </ListContainer>
        </ChallengeContainer>
        <ChallengeContainer>
          <StandardText textStyle='paragraph1'>
            What are our competitors doing?
          </StandardText>
          <StandardText textStyle='paragraph2'>
            We observed Steve Madden, Toms, Theo Chocolate, and Anthropologie. They were all very different businesses, but they had three things in common: 
          </StandardText>
          <ListContainer>
            <ol>
              <li>
                <StandardText textStyle='paragraph2'>
                  Easily navigatable categories
                </StandardText>
              </li>
              <li>
                <StandardText textStyle='paragraph2'>
                  Clarity in offered products
                </StandardText>
              </li>
              <li>
                <StandardText textStyle='paragraph2'>
                  Communicating business values
                </StandardText>
              </li>
            </ol>
          </ListContainer>
        </ChallengeContainer>
        <SectionHead header='clarifying painpoints & goals' />
        <StandardText textStyle='paragraph1'>
          What is the problem?
        </StandardText>
        <StandardText textStyle='paragraph2'>
          People who land on the Meraki Design House abandon the site due to a lack of business information, values, and product details on the landing page.
        </StandardText>
        <GoalsContainer>
          <StandardText textStyle='paragraph1'>
            Let's clear some goals
          </StandardText>
        </GoalsContainer>
        <StandardText textStyle='paragraph2'>
          Business: Increase conversion rate by engaging first-time users
        </StandardText>
        <StandardText textStyle='paragraph2'>
          User: Find shoes that fit their taste through a pleasant and effortless shopping experience, knowing that Meraki     Design House is a trustworthy site and a business
        </StandardText>
        <GoalsContainer>
          <StandardText textStyle='paragraph1'>
            What is our solution hypothesis?
          </StandardText>
        </GoalsContainer>
        <StandardText textStyle='paragraph2'>
          Motivate first-time users to shop on Meraki website by providing clear ontology for menu options, communicating Meraki’s missions and goals, and creating a more efficient way to narrow down their shoe searching experience.
        </StandardText>
      </ContentContainer>
    </Container>
  )
};

export default ExploringSection;

const GoalsContainer = styled(FlatContainer)`
  width: 100%;
  margin-top: 30px;
`

const StandardText = styled(Text)``

const Container = styled(ColumnContainer)`
  padding: 100px 0px;
  background: #a1afb3;
  width: 100%;
  ${StandardText} {
    margin-bottom: 15px;
  }
`

const ListContainer = styled(ColumnContainer)`
  width: 100%;

  ol {
    margin-bottom: 50px;
  }
`

const ContentContainer = styled(ColumnContainer)`
  align-items: flex-start;
  justify-content: flex-start;
  width: 954px;
  overflow: visible;
`

const ImageContainer = styled(ColumnContainer)`
  margin: 25px 0px 50px;
  width: 954px;
    margin-bottom: 50px;
  }
`

const ChallengeContainer = styled(ColumnContainer)`
  align-items: flex-start;
  justify-content: flex-start;
  width: 954px;
  margin-bottom: 50px;
`