import React from 'react';
import styled from 'styled-components';
import { Text, Divider, ColumnContainer } from '.'

const SectionHead = (props) => {
  const { header } = props;

  return (
    <Container>
      <Text textStyle='generalTitle'>
        {header.toUpperCase()}
      </Text>
      <Divider />
    </Container>
  );
};

export default SectionHead;

const Container = styled(ColumnContainer)`
  width: 100%;
  align-items: flex-start;
  margin-bottom: 32px;
`