import React from 'react';
import styled from 'styled-components';
import { ColumnContainer, Text, SectionHead } from '../../dls';
import CurrentOnboardingScreens from '../_images/current-onboarding-screens.png';
import InviteFlow from '../_images/invite-flow.png';
import NoomAnalysis from '../_images/noom-analysis.png';
import SimpleHabitAnalysis from '../_images/simplehabit-analysis.png';
import Table from '../_images/table.png';


const ExploringSection = () => {
  return (
    <Container>
      <ContentContainer>
       <SectionHead header='exploring aura' />
        <TitleText textStyle='paragraph1'>
          Getting Acquainted
        </TitleText>
        <StandardText textStyle='paragraph2'>
          I started this project by learning about Aura and the current onboarding process. I met with the marketing lead and the CEO to understand design decisions and what we hoped to achieve by redesigning the onboarding. Then I explored the current onboarding flow and conducted a heuristic evaluation. This step helped me visually break down the different sections and the purpose that they serve.
        </StandardText>
        <ImageContainer>
          <StandardText textStyle='paragraph2'>
            Current onboarding pro cess
          </StandardText>
          <img src={CurrentOnboardingScreens} alt='current-onboarding-screens' />
          <StandardText textStyle='paragraph2'>
            Analysis of current onboarding process:
          </StandardText>
          <img src={InviteFlow} alt='invite-flow' />
          <StandardText textStyle='paragraph2'>
            Aura’s onboarding flows follow a linear form, and the structure would stay the same even with my redesign. My goal would be to explore new interactions with the onboarding experience and inspire users to feel a necessity to sign up for a subscription.
          </StandardText>
        </ImageContainer>
        <TitleText textStyle='paragraph1'>
          User Test Research
        </TitleText>
        <StandardText textStyle='paragraph2'>
          I had five individuals go through the current onboarding process via zoom and share their experiences. Their task was to go through and sign up for a subscription.
        </StandardText>
        <StandardText textStyle='paragraph2'>
          My two main goals in conducting user testing were to observe and collect users’ reactions to questions and thoughts along the onboarding process.
        </StandardText>
        <InsightContainer>
          <TitleText textStyle='paragraph1'>
            Insights From Users
          </TitleText>
          <ColumnContainer>
            <StandardText textStyle='paragraph2'>
              ”I’m not sure I need this.”
            </StandardText>
            <StandardText textStyle='paragraph2'>
              ”I won’t know how to use it well to make my money worth.”
            </StandardText>
            <StandardText textStyle='paragraph2'>
              ”How would this help me?”
            </StandardText>
          </ColumnContainer>
        </InsightContainer>
        <ChallengeContainer>
          <TitleText textStyle='paragraph1'>
            The Challenge:
          </TitleText>
          <ul>
            <li>
              <StandardText textStyle='paragraph2'>
                Users expressed their confusion about what Aura is and what it would provide as a service. 
              </StandardText>
            </li>
            <li>
              <StandardText textStyle='paragraph2'>
                A few users were uncertain of their needs because they did not quite understand their situation, therefore, their needs.
              </StandardText>
            </li>
            <li>
              <StandardText textStyle='paragraph2'>
                No matter how different the users were, they all shared an understanding that their situation was unique. Users prioritize that they are heard and understood by the platform.
              </StandardText>
            </li>
          </ul>
        </ChallengeContainer>
        <ImageContainer>
          <TitleText textStyle='paragraph1'>
            Competitive Analysis
          </TitleText>
          <StandardText textStyle='paragraph2'>
            To better understand the onboarding experiences offered in the competitive market, I conducted a competitive analysis. Many of those onboarding processes consisted of more elements such as diagnostic questions, goal-oriented questions, and advertisements of their platform.
          </StandardText>
          <FullScreenImageContainer>
            <img src={NoomAnalysis} alt='noom-analysis' />
            <img src={SimpleHabitAnalysis} alt='simple-habit-analysis' />
          </FullScreenImageContainer>
        </ImageContainer>
        <SectionHead header='exploring possibilities' />
        <ImageContainer>
          <TitleText textStyle='paragraph1'>
            Ideation
          </TitleText>
          <img src={Table} alt='table' />
        </ImageContainer>
      </ContentContainer>
    </Container>
  )
};

export default ExploringSection;

const StandardText = styled(Text)``

const Container = styled(ColumnContainer)`
  padding: 100px 0px;
  background: #a1afb3;
  width: 100%;
  ${StandardText} {
    margin-bottom: 20px;
  }
`

const ContentContainer = styled(ColumnContainer)`
  align-items: flex-start;
  justify-content: flex-start;
  width: 954px;
  overflow: visible;
`

const ImageContainer = styled(ColumnContainer)`
  margin: 25px 0px 50px;
  align-items: flex-start;
  justify-content: flex-start;
  width: 954px;

  img {
    margin-bottom: 50px;
  }
`

const TitleText = styled(Text)`
  margin-bottom: 20px;
`

const FullScreenImageContainer = styled(ColumnContainer)`
  margin: 25px 0px 50px;
  height: 400px;

  img {
    position: absolute;
    left: 250px;
  }
`

const InsightContainer = styled(ColumnContainer)`
  align-items: flex-start;
  justify-content: flex-start;
  width: 954px;
  margin: 30px 0px;

  ${ColumnContainer} {
    margin: 40px 0px;
    width: 954px;
  }
`

const ChallengeContainer = styled(ColumnContainer)`
  align-items: flex-start;
  justify-content: flex-start;
  width: 954px;
  margin-bottom: 50px;
`