import React from 'react';
import styled from 'styled-components';
import IconBlurb from './components/IconBlurb';
import { FlatContainer, ColumnContainer, Text } from '../dls';
import JoyceAboutMe from './_images/Joyce_About_Me.png';
import { iconBlurbConfigs } from './components/iconBlurbConfigs';
import Messaging from './components/_images/messaging.svg';
import JoyceCello from './_images/joyce_cello.png';
import MauiHalloween from './_images/maui-halloween.png';
import GroupedPhotos from './_images/grouped-photos.png';
import Footer from '../Footer/Footer';
import Mickey from './_images/mickey.svg'

const AboutPage = () => {
  return (
    <Container>
      <IntroContainer>
        <img src={JoyceAboutMe} alt='joyce-hawaii' />
        <ColumnContainer>
          <Text textStyle='title'>
            Hi there, I’m Joyce
          </Text>
          <Text textStyle='paragraph2'>
            I am a product designer based in Oakland, California. I am a self-motivated end-to-end designer passionate about leading in-depth research, ideating solutions outside the box, and creating meaningful experiences.
          </Text>
          <Text textStyle='paragraph2'>
            The pandemic changed many peoples’ lives, regular routines, and social interactions, but it was my career for me. As a cellist and an educator in the past, and now as a product designer, I continue to tap into my curiosity by nature, learn and polish new skills, and find joy in collaboration and teamwork.
          </Text>
        </ColumnContainer>
      </IntroContainer>
      <ChatWithMeContainer>
        <IconBlurb title='Chat with me about:' textStyle='title' icon={<img src={Messaging} alt='messaging'/>} />
        <FlatContainer>
          <BlurbContainer>
          {iconBlurbConfigs.map(config => (
            <IconBlurb title={config.title} blurb={config.blurb} icon={config.icon} />
            ))}
          </BlurbContainer>
          <ChatWithMePicturesContainer justifyContent='space-around'>
            <img src={JoyceCello} alt='joyce-cello' />
            <img src={MauiHalloween} alt='maui-halloween' />
          </ChatWithMePicturesContainer>
        </FlatContainer>
      </ChatWithMeContainer>
      <TestimonialsContainer>
        <Text textStyle='title'>
          Testimonials
        </Text>
        <Text textStyle='paragraph2'>
          Joyce worked with me as a UX Design and Research intern at Aura, while I was head of Lifecycle Marketing. She was tasked with improving conversion of Aura’s onboarding flow and uncovering insights to inform the roadmap for a new product that was in development.
        </Text>
        <Text textStyle='paragraph2'>
          She immediately impressed me with her curiosity, intuitive technical skills and drive for impact. Within her first few weeks, she was providing meaningful insights and mapped out a new UX and experiment roadmap for onboarding, working collaboratively with design, engineering and product to bring it to life and drive significant increase to customer conversion.
        </Text>
        <Text textStyle='paragraph2'>
          She is naturally empathetic, able to build trust quickly and a strong communicator - all of this was clearly evidenced in the interviews she conducted. Plus, she is a pleasure to work with and exudes positivity no matter what comes her way. Any team would be fortunate to have Joyce on their team!
        </Text>
        <NicoleContainer justifyContent='flex-end'>
          <div>
            <Text textStyle='paragraph2'>
              -Nicole Jones, Marketing & Growth Consultant @ Nana Academy
              (former Head of Lifecycle & Retention Marketing @ Aura Health)
            </Text>
          </div>
        </NicoleContainer>
      </TestimonialsContainer>
      <PhotosContainer>
        <img src={Mickey} alt='mickey' />
        <img src={GroupedPhotos} alt='grouped-photos' />
      </PhotosContainer>
      <Footer />
    </Container>
  )
}

export default AboutPage;

const Container = styled(ColumnContainer)`
  background-image:
    linear-gradient(360deg, #E9DCC9 0%, rgba(233, 220, 201, 0) 71.15%),
    linear-gradient(180deg, #798C92 0%, rgba(121, 140, 146, 0) 34.81%);
`

const IntroContainer = styled(FlatContainer)`
  background: transparent;
  width: 1100px;

  img {
    height: 464px;
    width: 467px;
    margin-right: 100px;
  }

  ${ColumnContainer} {
    width: 500px;
    align-items: flex-start;
  }

  ${Text} {
    margin: 10px 0px;
  }
`

const ChatWithMeContainer = styled(ColumnContainer)`
  margin-top: 125px;
  align-items: flex-start;
  width: 1100px;
  margin-bottom: 100px;
`

const ChatWithMePicturesContainer = styled(ColumnContainer)`
  height: 995px;
`
const BlurbContainer = styled(ColumnContainer)`
  margin-right: 100px;
`

const TestimonialsContainer = styled(ColumnContainer)`
  align-items: flex-start;
  width: 1100px;
  margin-bottom: 100px;

  ${Text} {
    margin-bottom: 15px;
  }
`

const NicoleContainer = styled(ColumnContainer)`
  align-items: flex-end;
  width: 1100px;

  div {
    width: 575px;
  }
`

const PhotosContainer = styled(FlatContainer)`
  justify-content: flex-end;
  background: transparent;
  width: 1440px;
`