import React from 'react';
import styled from 'styled-components';
import Prototype from '../_images/prototype.gif';
import GifBorder from '../_images/gif-border.png';
import { ColumnContainer, FlatContainer, Text, color, Divider } from '../../dls';

const OverviewSection = () => {
return (
  <OverviewSectionContainer>
        <OverviewContentContainer>
          <Text textStyle='generalTitle'>
            OVERVIEW
          </Text>
          <Divider />
          <InnerOverviewContentContainer justifyContent='space-between'>
            <OverviewContentLeftContainer>
              <OverviewHeaderContainer marginUnset>
                <Text textStyle='title'>
                  Aura Health
                </Text>
                <Text textStyle='paragraph2'>
                  A mobile app that helps individuals find their mental wellness through meditations
                </Text>
                <Text textStyle='paragraph2'>
                  In the spring-summer of 2021, I had a UX design internship with Aura Health and was assigned to work on the onboarding process for new users as a part of their conversion rate optimization project.
                </Text>
              </OverviewHeaderContainer>
              <ChallengeOutcomeContainer justifyContent='space-between'>
                <ColumnContainer>
                  <Text textStyle='paragraph1'>
                    Challenge
                  </Text>
                  <Text textStyle='paragraph2'>
                    Mixpanel showed significant drop-offs in users during the onboarding process, and only about 35% of users signed up for a subscription. My task was to figure out why and redesign a new onboarding process.
                  </Text>
                </ColumnContainer>
                <ColumnContainer>
                  <Text textStyle='paragraph1'>
                    Outcome
                  </Text>
                  <Text textStyle='paragraph2'>
                    I delivered a new onboarding experience after thorough research and new learnings. The new onboarding process improved the conversion rate and increased new user sign-ups by 40%.
                  </Text>
                </ColumnContainer>
              </ChallengeOutcomeContainer>
            </OverviewContentLeftContainer>
            <OverviewContentRightContainer>
              <TitleText textStyle='paragraph1'>
                My role
              </TitleText>
              <Text textStyle='paragraph2'>
                User Experience Designer
              </Text>
              <Text textStyle='paragraph2'>
                User Interface Designer
              </Text>
              <Text textStyle='paragraph2'>
                User Experience Researcher
              </Text>
              <Text textStyle='paragraph2'>
                Content Strategy
              </Text>
              <Text textStyle='paragraph2'>
                Prototyping
              </Text>
              <SecondaryTitleText textStyle='paragraph1'>
                Timeline
              </SecondaryTitleText>
              <Text textStyle='paragraph2'>
                May 2021 to June 2021
              </Text>
              <SecondaryTitleText textStyle='paragraph1'>
                Tools
              </SecondaryTitleText>
              <Text textStyle='paragraph2'>
                Figma
              </Text>
              <Text textStyle='paragraph2'>
                Mixpanel
              </Text>
              <Text textStyle='paragraph2'>
                Zoom
              </Text>
              <SecondaryTitleText textStyle='paragraph1'>
                Team
              </SecondaryTitleText>
              <Text textStyle='paragraph2'>
                Joyce Tsunekawa
              </Text>
            </OverviewContentRightContainer>
          </InnerOverviewContentContainer>
          <Text textStyle='paragraph1'>
            Final Designs
          </Text>
          <GifContainer>
            <img src={Prototype} alt='prototype' />
            <BorderContainer>
              <img src={GifBorder} alt='gif-border' />
            </BorderContainer>
          </GifContainer>
        </OverviewContentContainer>
    </OverviewSectionContainer>
)
}

export default OverviewSection;

const OverviewSectionContainer = styled(ColumnContainer)`
  padding: 100px 0px 50px 0px;;
  background-color: ${color.yellow};
  width: 100%;
`

const OverviewContentContainer = styled(ColumnContainer)`
  align-items: flex-start;
  justify-content: flex-start;
  width: 954px;
`

const InnerOverviewContentContainer = styled(FlatContainer)`
  width: 954px;
  margin-bottom: 50px;
`

const OverviewContentLeftContainer = styled(ColumnContainer)`
  width: 640px;
  justify-content: space-between;
`

const TitleText = styled(Text)``

const SecondaryTitleText = styled(Text)``

const OverviewContentRightContainer = styled(ColumnContainer)`
  width: 240px;
  align-items: flex-start;
  justify-content: flex-start;
  ${TitleText} {
    margin-bottom: 15px;
  }
  ${SecondaryTitleText} {
    margin: 24px 0px 0px 0px;
  }
`
const OverviewHeaderContainer = styled(FlatContainer)`
  ${Text} {
    margin-bottom: 15px;
  }
`

const ChallengeOutcomeContainer = styled(FlatContainer)`
  width: 650px;
  margin-top: 125px;
  ${ColumnContainer} {
    height: 200px;
    width: 315px;
    align-items: flex-start;
    ${Text} {
      margin-bottom: 10px;
    }
  }
`

const GifContainer = styled(ColumnContainer)`
  height: 600px;
  width: 100%;
`
const BorderContainer = styled.div`
  position: relative;
  top: -512px;
  left: -160px;
  height: 0px;
  width: 0px;
  img {
    position: absolute;
  }
`