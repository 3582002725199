import React from 'react';
import styled from 'styled-components';
import { ColumnContainer, Text } from '../dls';
import MockupPhones from './_images/mockup-phones.png';
import OverviewSection from './components/OverviewSection';
import ExploringSection from './components/ExploringSection';
import DesignProcessSection from './components/DesignProcessSection';
import Footer from '../Footer/Footer';

const AuraPage = () => {
  return (
    <>
    <Container>
      <HeaderContainer>
        <Header textStyle='heading'>
          Aura Health Onboarding Experience
        </Header>
        <img src={MockupPhones} alt='mockup-phones' />
      </HeaderContainer>
      <OverviewSection />
      <ExploringSection />
      <DesignProcessSection />
    </Container>
    <Footer />
    </>
  )
}

export default AuraPage

const Container = styled(ColumnContainer)`
  overflow: hidden;
`

const Header = styled(Text)`
  margin-bottom: 25px;
`

const HeaderContainer = styled(ColumnContainer)`
  padding: 25px 0px 0px 0px;
  width: 100%;
  img {
    width: 100%;
  }
  background-image:
  linear-gradient(360deg, #E9DCC9 0%, rgba(233, 220, 201, 0) 71.15%),
  linear-gradient(180deg, #798C92 0%, rgba(121, 140, 146, 0) 34.81%);
`
