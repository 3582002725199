import React from 'react';
import styled from 'styled-components';

const Divider = () => {
  return (
    <Dividor />
  )
}

const Dividor = styled.div`
  margin: 10px 0px;
  width: 100%;
  border: 1px solid black;
`;

export default styled(Divider)``;