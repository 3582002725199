import React from "react";
import styled from "styled-components";
import { FlatContainer, color, Text } from "../dls";
import { Link } from 'react-router-dom';
import { HashLink } from "react-router-hash-link";
import Logo from './_images/logo.svg';
import HeaderLink from './HeaderLink';
import Resume from './Resume.pdf'

const Header = (props) => {
 return (
   <HeaderContainer justifyContent='space-between' marginUnset>
      <LogoContainer>
        <StyledLink to='/'>
          <img src={Logo} alt="logo" />
        </StyledLink>
      </LogoContainer>
     <LinksContainer justifyContent='space-around'>
      <StyledHashLink smooth to='/#work'>
        <Text textStyle='menu'>
          Work
        </Text>
      </StyledHashLink>
      <HeaderLink to='/about' text='About' />
      <StyledAnchor href={Resume} target='_blank' rel='noreferrer'>
        <Text textStyle='menu'>
          Resume
        </Text>
      </StyledAnchor>
     </LinksContainer>
   </HeaderContainer>
 )
}

export default Header;

const HeaderContainer = styled(FlatContainer)`
  height: 100px;
  width: 100%;
  background-color: ${color.lightBlue};
  position: fixed;
  z-index: 100;
`

const StyledHashLink = styled(HashLink)`
  text-decoration: none;
  color: black;
  &:hover {
    color: ${color.pastelRed};
  };
`

const StyledAnchor = styled.a`
  text-decoration: none;
  color: black;
  &:hover {
    color: ${color.pastelRed};
  };
  svg {
    margin-left: 10px;
  }
`

const LogoContainer = styled.div`
  margin-left: 150px;
  img {
    height: 100px;
    width: 79px;
  }
`

const LinksContainer = styled(FlatContainer)`
  width: 400px;
  margin: 0px 40px;
`

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  &:hover {
    color: ${color.pastelRed};
  }
`