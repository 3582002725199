import React from 'react';
import HomePage from '../HomePage/HomePage';
import AboutPage from '../AboutPage/AboutPage';
import AuraPage from '../AuraPage/AuraPage';
import MerakiPage from '../MerakiPage/MerakiPage';
import styled from 'styled-components';
import Route from './Router/Route';
import './App.css';
import { BrowserRouter, Switch } from 'react-router-dom';
import Header from '../Header/Header';

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Body>
        <Switch>
          <Route
            name="home"
            exact
            path="/"
            component={HomePage}
            title={'Home | Joyce Tsunekawa'}
            />
          <Route
            name="about"
            exact
            path="/about"
            component={AboutPage}
            title={'About | Joyce Tsunekawa'}
          />
          <Route
            name="about"
            exact
            path="/aura_health"
            component={AuraPage}
            title={'Aura Health | Joyce Tsunekawa'}
          />
           <Route
            name="about"
            exact
            path="/meraki"
            component={MerakiPage}
            title={'Meraki | Joyce Tsunekawa'}
          />
        </Switch>
      </Body>
    </BrowserRouter>
  );
}

export default App;

const Body = styled.div`
  position: relative;
  padding-top: 100px;
  height: 100%;
  width: 100%;
`
