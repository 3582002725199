import React from 'react';
import styled from 'styled-components';
import { ColumnContainer, FlatContainer, Text, color, Divider } from '../../dls';
import MerakiGif from '../images/meraki-gif.gif';

const OverviewSection = () => {
return (
  <OverviewSectionContainer>
        <OverviewContentContainer>
          <Text textStyle='generalTitle'>
            OVERVIEW
          </Text>
          <Divider />
          <InnerOverviewContentContainer justifyContent='space-between'>
            <OverviewContentLeftContainer>
              <OverviewHeaderContainer marginUnset>
                <Text textStyle='title'>
                  Meraki Design House
                </Text>
                <Text textStyle='paragraph2'>
                  Meraki Design House, based in Toronto, Canada, offers one-of-a-kind leather flats, and accessories to women with colorful tastes
                </Text>
                <Text textStyle='paragraph2'>
                  One of our final projects at General Assembly included a real-life client where my team and I worked collaboratively as well as handling our own individual assignments.
                </Text>
              </OverviewHeaderContainer>
              <ChallengeOutcomeContainer justifyContent='space-between'>
                <ColumnContainer>
                  <Text textStyle='paragraph1'>
                    Challenge
                  </Text>
                  <Text textStyle='paragraph2'>
                    Meraki’s website metrics showed that many users abandoned their cart during their shopping process.
                  </Text>
                </ColumnContainer>
                <ColumnContainer>
                  <Text textStyle='paragraph1'>
                    Outcome
                  </Text>
                  <Text textStyle='paragraph2'>
                    We delivered a redesigned shopping experience to accomodate pain points that we discovered in our user research. The owner of Meraki Design adapted our final redesigns to her website.
                  </Text>
                </ColumnContainer>
              </ChallengeOutcomeContainer>
            </OverviewContentLeftContainer>
            <OverviewContentRightContainer>
              <TitleText textStyle='paragraph1'>
                My role
              </TitleText>
              <Text textStyle='paragraph2'>
                User Experience Designer
              </Text>
              <Text textStyle='paragraph2'>
                User Interface Designer
              </Text>
              <Text textStyle='paragraph2'>
                Project Manager
              </Text>
              <SecondaryTitleText textStyle='paragraph1'>
                Timeline
              </SecondaryTitleText>
              <Text textStyle='paragraph2'>
                Nov 2020 to Dec 2020
              </Text>
              <SecondaryTitleText textStyle='paragraph1'>
                Tools
              </SecondaryTitleText>
              <Text textStyle='paragraph2'>
                Figma
              </Text>
              <Text textStyle='paragraph2'>
                Whimsical
              </Text>
              <Text textStyle='paragraph2'>
                Zoom
              </Text>
              <SecondaryTitleText textStyle='paragraph1'>
                Team
              </SecondaryTitleText>
              <Text textStyle='paragraph2'>
                Joyce Tsunekawa
              </Text>
              <Text textStyle='paragraph2'>
                Mijin Kwon
              </Text>
              <Text textStyle='paragraph2'>
                Stephanie Spiegel
              </Text>
            </OverviewContentRightContainer>
          </InnerOverviewContentContainer>
          <Text textStyle='paragraph1'>
            Final Designs
          </Text>
          <FullScreenImageContainer>
            <img src={MerakiGif} alt='meraki-gif' />
          </FullScreenImageContainer>
        </OverviewContentContainer>
    </OverviewSectionContainer>
)
}

export default OverviewSection;

const FullScreenImageContainer = styled(ColumnContainer)`
  width: 100%;
  margin: 50px 0px;
`

const OverviewSectionContainer = styled(ColumnContainer)`
  padding: 100px 0px 50px 0px;;
  background-color: ${color.yellow};
  width: 100%;
`

const OverviewContentContainer = styled(ColumnContainer)`
  align-items: flex-start;
  justify-content: flex-start;
  width: 954px;
`

const InnerOverviewContentContainer = styled(FlatContainer)`
  width: 954px;
  margin-bottom: 50px;
`

const OverviewContentLeftContainer = styled(ColumnContainer)`
  width: 640px;
  justify-content: space-between;
`

const TitleText = styled(Text)``

const SecondaryTitleText = styled(Text)``

const OverviewContentRightContainer = styled(ColumnContainer)`
  width: 240px;
  align-items: flex-start;
  justify-content: flex-start;
  ${TitleText} {
    margin-bottom: 15px;
  }
  ${SecondaryTitleText} {
    margin: 24px 0px 0px 0px;
  }
`
const OverviewHeaderContainer = styled(FlatContainer)`
  ${Text} {
    margin-bottom: 15px;
  }
`

const ChallengeOutcomeContainer = styled(FlatContainer)`
  width: 650px;
  margin-top: 125px;
  ${ColumnContainer}:first-child {
    margin-top: -90px;
  }
  ${ColumnContainer} {
    height: 200px;
    width: 315px;
    align-items: flex-start;
    ${Text} {
      margin-bottom: 10px;
    }
  }
`