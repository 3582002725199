import styled from 'styled-components';
import { Link as LinkRouter } from 'react-router-dom';
import { color } from './_common';

const Link = styled(LinkRouter).attrs((props) => ({
  to: props.to,
}))`
  border: unset;
  color: ${color.linkBlue};
  font-size: 0.875rem;
  line-height: 1.53846154rem;
  padding: 0px;
  cursor: pointer;

  &:hover,
  :visited {
    text-decoration: underline;
    color: ${color.eggplant};
  }
`;

export default Link;
