import React from 'react';
import styled from 'styled-components';
import { ColumnContainer, Text, color, FlatContainer, SectionHead } from '../../dls';
import MerakiDesignSketches from '../images/meraki-design-sketches.svg'
import UsabilityTestLearnings from '../images/usability-test-learnings.svg';
import MerakiLandingPage from '../images/meraki-landing-page.svg'
import MerakiShopPage from '../images/meraki-shop-page.svg'
import MerakiProductPage from '../images/meraki-product-page.svg'
import MerakiGif from '../images/meraki-gif.gif';

const DesignSection = () => {
  return (
    <Container>
      <ContentContainer>
        <SectionHead header='design process' />
        <StandardText textStyle='paragraph1'>
          Design Studio
        </StandardText>
        <ImageContainer>
          <img src={MerakiDesignSketches} alt='meraki-design-sketches' />
        </ImageContainer>
        <StandardText textStyle='paragraph1'>
          High-fidelity and iterations
        </StandardText>
        <CenteredImageContainer>
          <img src={UsabilityTestLearnings} alt='meraki-design-sketches' />
        </CenteredImageContainer>
      </ContentContainer>
      <FinalContainer>
        <ContentContainer>
          <SectionHead header='final designs' />
          <HeaderContainer>
            <CenteredContainer>
              <StandardText textStyle='title'>
                What's new, different, and why?
              </StandardText>
            </CenteredContainer>
          </HeaderContainer>
          <CenteredContainer>
            <Text textStyle='paragraph1'>
              Landing page
            </Text>
          </CenteredContainer>
          <CenteredImageContainer>
            <img src={MerakiLandingPage} alt='meraki-final-designs' />
          </CenteredImageContainer>
          <CenteredContainer>
            <Text textStyle='paragraph1'>
              Shop
            </Text>
          </CenteredContainer>
          <CenteredImageContainer>
            <img src={MerakiShopPage} alt='meraki-final-designs' />
          </CenteredImageContainer>
          <CenteredContainer>
            <Text textStyle='paragraph1'>
              Product Details
            </Text>
          </CenteredContainer>
          <CenteredImageContainer>
            <img src={MerakiProductPage} alt='meraki-final-designs' />
          </CenteredImageContainer>
        </ContentContainer>
      </FinalContainer>
      <FinalFinalContainer>
        <ContentContainer>
          <SectionHead header='final designs' />
            <CenteredContainerNoMargin>
              <a href='https://www.figma.com/proto/fcLtmBxqdmlOV2o7Ig2g7D/Meraki?page-id=119%3A92&node-id=119%3A104&viewport=241%2C48%2C0.41&scaling=min-zoom&starting-point-node-id=119%3A104' target='_blank' rel="noreferrer">
                <UnderlinedText textStyle='paragraph1'>
                  Experience the new Meraki Design Home!
                </UnderlinedText>
              </a>
            </CenteredContainerNoMargin>
            <FullScreenImageContainer>
              <img src={MerakiGif} alt='meraki-gif' />
            </FullScreenImageContainer>
            <SectionHead header='what i learned' />
            <LearnedContainer justifyContent='space-between'>
              <LeftContainer>
                <StandardText textStyle='paragraph1'>
                  Balance the voices
                </StandardText>
                <StandardText textStyle='paragraph2'>
                  Even with three people on our team, we had three very different personalities and work styles. It was one of the first things that I realized, and I prioritized ensuring that everyone’s thought processes, creative ideas, and inputs were all heard throughout our collaborations. In my musical career and classroom teachings, I have witnessed more assertive personalities and/or more outspoken people sharing their ideas. This led to missed opportunities and an unhealthy work/learning environment. I facilitated our group meetings to stay balanced so that all thoughts were heard and discussed equally. This resulted in our group members feeling safe, expressed and helped me continue to believe in the importance of balancing the voices.
                </StandardText>
              </LeftContainer>
              <RightContainer>
                <StandardText textStyle='paragraph1'>
                  What really happened
                </StandardText>
                <StandardText textStyle='paragraph2'>
                  Our team was thorough with our future plans and upcoming events, and they held us accountable and guided us with delegating responsibilities. But now that I look back, we don’t have many records of what we ended up doing. We had to make some adjustments along the way due to some constraints and learnings from those necessary adjustments. A retrospective summary could be useful to improve teamwork and collaborative skills for future group projects. 
                </StandardText>
              </RightContainer>
            </LearnedContainer>
          </ContentContainer>
        </FinalFinalContainer>
    </Container>
  )
}

export default DesignSection;

const StandardText = styled(Text)``

const Container = styled(ColumnContainer)`
  padding: 100px 0px;
  background-color: ${color.yellow};
  width: 100%;
  ${StandardText} {
    margin-bottom: 15px;
  }
`

const FinalContainer = styled(ColumnContainer)`
  padding: 100px 0px;
  background-color: #a1afb3;
  width: 100%;
  overflow: visible;
`

const FinalFinalContainer = styled(ColumnContainer)`
  padding: 100px 0px;
  background-color: ${color.yellow};
  width: 100%;
  overflow: visible;
`


const CenteredImageContainer = styled(ColumnContainer)`
  width: 100%;
  margin-bottom: 75px;
`
const ImageContainer = styled(ColumnContainer)`
  margin: 50px 0px 50px;
  align-items: flex-start;
  justify-content: flex-start;
  width: 954px;

  img {
    margin-bottom: 50px;
  }
`

const HeaderContainer = styled.div`
  margin-bottom: 100px;
`

const ContentContainer = styled(ColumnContainer)`
  align-items: flex-start;
  justify-content: flex-start;
  width: 954px;
  overflow: visible;
`

const CenteredContainer = styled(ColumnContainer)`
  width: 954px;
  margin-bottom: 25px;
`


const CenteredContainerNoMargin = styled(ColumnContainer)`
  width: 954px;

  a {
    text-decoration: none;
  }

`

const UnderlinedText = styled(Text)`
  margin-top: 50px;
  font-size: 32px;
  font-family: 'Lora, serif';
  text-decoration: underline;
`

const FullScreenImageContainer = styled(ColumnContainer)`
  width: 100%;
  margin: 50px 0px;
`

const LeftContainer = styled(ColumnContainer)`
  align-items: flex-start;
  justify-content: flex-start;
  width: 420px;
`

const RightContainer = styled(ColumnContainer)`
  align-items: flex-start;
  justify-content: flex-start;
  width: 420px;
`

const LearnedContainer = styled(FlatContainer)`
  margin-top: 25px;
  align-items: flex-start;
  width: 100%;
`