import React from 'react';
import styled from 'styled-components';
import { FlatContainer,ColumnContainer, Text } from '../dls';
import LinkedIn from './_images/LinkedIn.svg'
import Gmail from './_images/Gmail.svg'

const Footer = () => (
  <Container>
    <Text textStyle='heading'>
      Thanks for visiting my portfolio
    </Text>
    <SocialContainer>
      <Text textStyle='paragraph2'>
        Let's connect
      </Text>
      <LinksContainer marginUnset>
        <a href="https://www.linkedin.com/in/joycetsunekawa/" target="_blank" rel="noopener noreferrer">
          <img src={LinkedIn} alt='linkedin' />
        </a>
        <a href="mailto:joycetsunekawa@gmail.com">
         <img src={Gmail} alt='gmail' />
        </a>
      </LinksContainer>
    </SocialContainer>
  </Container>
);

export default styled(Footer)``;


const Container = styled.div`
  text-align: center;
  height: 331px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: space-around;
  justify-content:center;
  position: relative;
  background: linear-gradient(180deg, rgba(233, 220, 201, 0.2) 0%, rgba(233, 220, 201, 0) 34.28%), linear-gradient(360deg, #798C92 0%, rgba(121, 140, 146, 0) 100%);
`;

const SocialContainer = styled(ColumnContainer)`
  margin-top: 50px;
`

const LinksContainer = styled(FlatContainer)`
  a {
    margin: 0px 10px;
  }
`