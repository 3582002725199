import LightOn from './_images/light-on.svg';
import TrebleClef from './_images/treble-clef.svg';
import ReadingUnicorn from './_images/reading-unicorn.svg';
import DisneyIcon from './_images/disney-icon.svg';
import PawPrints from './_images/paw-prints.svg';

export const iconBlurbConfigs = [
  {
    title: "Career change",
    blurb: "It is possibly the bravest decision I've ever made, and I thank my past self for making a move.",
    icon: <img src={LightOn} alt='light-on' />
  },
  {
    title: "Live Music",
    blurb: "I still perform with my talented friends at cool events, weddings, concerts, and fun gatherings! If you’re curious to learn more or even better, if you’re planning an event, I’m the person to talk to!",
    icon: <img src={TrebleClef} alt='treble-clef' />
  },
  {
    title: "Reading",
    blurb: "Besides my phone getting charged every day, I make sure that my Kindle stays well-charged. I’m currently reading The Stormlight Archive series by Brandon Sanderson.",
    icon: <img src={ReadingUnicorn} alt='reading-unicorn' />
  },
  {
    title: "Anything Disney",
    blurb: "As an annual pass holder for many years, I’ve found many cool hacks for Disneyland as well as cool Disney pins. Have you seen the hidden Mickey on this page?",
    icon: <img src={DisneyIcon} alt='disney' />
  },
  {
    title: "My Dog",
    blurb: "During the strange year of 2020, my husband and I jumped on the pandemic dog trend train! Check out his cuteness on his instagram @maui.thegolden.",
    icon: <img src={PawPrints} alt='paw-prints' />
  },
]