export const color = {
 lightBlue: '#798C92',
 lightBlue50: 'rgba(121, 140, 146, 0.5)',
 darkBlue: '#505D61',
 pastelRed: '#D6B0A7',
 snow: '#FBFBFD',
 yellow: '#E9DCC9',
};

export const font = {
  karla: 'Karla, sans-serif',
  poppins: 'Poppins, sans-serif',
  montserrat: 'Montserrat, serif',
  lora: 'Lora, serif',
  hind: 'Hind, serif',
  roboto: 'Roboto, sans-serif',
  cambay: 'Cambay, serif',
};

export const boxShadow = {
  Primary: '0px 4px 8px rgba(31, 24, 82, 0.05)',
  Secondary: '0px 4px 10px rgba(31, 24, 82, 0.1)',
};
