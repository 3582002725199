import React from 'react';
import styled from 'styled-components';
import { ColumnContainer, Text, color, Divider, FlatContainer } from '../../dls';
import Values from '../_images/values.png';
import Survey from '../_images/survey.png';
import SleepScore from '../_images/sleep-score.png';
import MockupPhones from '../_images/mockup-phones-2.png';

const DesignProcessSection = () => {
  return (
    <Container>
        <QuoteContainer>
          <Text textStyle='title'>
            How might we make an onboarding process to
          </Text>
          <Text textStyle='title'>
            share value, present a necessity and ensure
          </Text>
          <Text textStyle='title'>
            personalization?
          </Text>
        </QuoteContainer>
      <ContentContainer>
        <Text textStyle='generalTitle'>
          DESIGN PROCESS
        </Text>
        <Divider />
        <Text textStyle='title'>
          First task: Show value
        </Text>
        <Text textStyle='paragraph2'>
          My goal was to shine the light on what Aura is as a mental app service and how it would support the user’s situation. I met with our marketing lead and our co-founders to narrow down the key values to share with the users.
        </Text>
        <CenteredContainer>
          <Text textStyle='paragraph1'>
            Main values of Aura
          </Text>
          <img src={Values} alt='values' />
        </CenteredContainer>
        <Text textStyle='title'>
          Second task: Convince the need
        </Text>
        <Text textStyle='paragraph2'>
          To accommodate users who were uncertain of their needs, I wanted to create a learning experience to help them understand their situation better. I sifted through numerous sleep studies and questionnaires to discover the various sleep issues that people experience. Then I created a set of diagnostic questions for users to reflect on their situation then followed with responses to their selected answers with fact prompts to provide the importance of improving their situation.
        </Text>
        <ImageContainer>
          <img src={Survey} alt='survey' />
        </ImageContainer>
        <Text textStyle='title'>
          Third task: Let situations be heard
        </Text>
        <Text textStyle='paragraph2'>
          I chose personalization throughout the onboarding process to make users’ unique situations be heard and empathized by the platform. I approached by providing various specific answer choices that would better correspond to users’ concerns. Then the answers were used to generate a personalized score that matches the users’ responses.
        </Text>
        <SleepScoreImageContainer>
          <img src={SleepScore} alt='sleep-score' />
        </SleepScoreImageContainer>
      </ContentContainer>
      <FinalContainer>
        <ContentContainer>
            <Text textStyle='generalTitle'>
              FINAL DESIGNS
            </Text>
            <Divider />
            <CenteredContainerNoMargin>
            <a href='https://www.figma.com/file/9vS5Og5ws2gcTPvFEoBeUL/Untitled?node-id=0%3A1' target='_blank' rel="noreferrer">
                <UnderlinedText textStyle='paragraph1'>
                  Experience the new onboarding prototype!
                </UnderlinedText>
              </a>
            </CenteredContainerNoMargin>
            <FullScreenImageContainer>
              <img src={MockupPhones} alt='mockup-phones' />
            </FullScreenImageContainer>
            <Text textStyle='generalTitle'>
              WHAT I LEARNED
            </Text>
            <Divider />
            <LearnedContainer justifyContent='space-between'>
              <LeftContainer>
                <Text textStyle='paragraph1'>
                  Overcommunicate
                </Text>
                <Text textStyle='paragraph2'>
                  Even though I was the only designer on this project, it involved numerous people: co-founders, marketing team, engineers, and test users. Their contributions and involvement were crucial in ensuring consistency from onboarding steps to the rest of Aura and understanding their design decisions in the past. It provided me with valuable information to expand my ideas and creativity. But most importantly, it helped me to make significant adjustments early on in my process. Thorough communication enabled collaboration and a sense of involvement, which is a challenge through remote work but a fantastic reward when it is found.
                </Text>
              </LeftContainer>
              <RightContainer>
                <Text textStyle='paragraph1'>
                  Keep things organized
                </Text>
                <Text textStyle='paragraph2'>
                  As the project went on, the number of files and their digital space grew. Keeping things labeled and organized make it easy access for everyone and create a more pleasant experience for anyone that interacts with them. I'd imagine that this would be key for bigger teams to stay clear from confusion and miscommunication.
                </Text>
              </RightContainer>
            </LearnedContainer>
          </ContentContainer>
        </FinalContainer>
    </Container>
  )
}

export default DesignProcessSection;

const Container = styled(ColumnContainer)`
  background-color: #a1afb3;
  width: 100%;
  ${Text} {
    margin-bottom: 15px;
  }
`

const ContentContainer = styled(ColumnContainer)`
  align-items: flex-start;
  justify-content: flex-start;
  width: 954px;
  overflow: visible;
`

const FinalContainer = styled(ColumnContainer)`
  padding: 100px 0px;
  background-color: ${color.yellow};
  width: 100%;
  overflow: visible;
  
  a {
    margin: 25px 0px;
  }
`

const QuoteContainer = styled(ColumnContainer)`
  width: 100%;
  padding: 100px 0px;
  margin-bottom: 100px;
  background-color: ${color.yellow};
`

const CenteredContainer = styled(ColumnContainer)`
  width: 954px;
  margin-bottom: 100px;
`


const CenteredContainerNoMargin = styled(ColumnContainer)`
  width: 954px;
`

const ImageContainer = styled(ColumnContainer)`
  margin: 50px 0px 50px;
  align-items: flex-start;
  justify-content: flex-start;
  width: 954px;

  img {
    margin-bottom: 50px;
  }
`
const SleepScoreImageContainer = styled(ColumnContainer)`
  margin: 50px 0px 50px;
  align-items: flex-start;
  justify-content: flex-start;
  width: 954px;
  margin-left: 100px;

  img {
    margin-bottom: 50px;
  }
`

const UnderlinedText = styled(Text)`
  margin-top: 50px;
  font-size: 32px;
  font-family: 'Lora, serif';
  text-decoration: underline;
`

const FullScreenImageContainer = styled(ColumnContainer)`
  width: 1500px;

  img {
    margin-left: -500px;
    margin-bottom: 50px;
  }
`

const LeftContainer = styled(ColumnContainer)`
  align-items: flex-start;
  justify-content: flex-start;
  width: 420px;
`

const RightContainer = styled(ColumnContainer)`
  align-items: flex-start;
  justify-content: flex-start;
  width: 420px;
`

const LearnedContainer = styled(FlatContainer)`
  margin-top: 50px;
  align-items: flex-start;
  width: 100%;
`