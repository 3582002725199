import styled from 'styled-components';

const ColumnContainer = styled.div.attrs((props) => ({
  justifyContent: props.justifyContent || 'center',
}))`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => props.justifyContent};
  justify-content: ${(props) => props.justifyContent};
`;

export default ColumnContainer;
