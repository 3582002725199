import styled from 'styled-components';
import { color, font } from './_common';

export const TextStyle = {
  heading: { fontType: 'montserrat', size: 48 },
  title: { fontType: 'lora', size: 40 },
  paragraph1: { fontType: 'lora', size: 32 },
  paragraph2: { fontType: 'hind', size: 20 },
  menu: { fontType: 'roboto', size: 18 },
  generalTitle: { fontType: 'cambay', size: 16 },
  h0: { size: 2, height: 3 } /* 32px, 48px */,
  h1: { size: 1.5, height: 2.5 } /* 24px, 40px */,
  h2: { size: 1.25, height: 2 } /* 20px, 32px */,
  h3: { size: 1, height: 1.5 } /* 16px, 24px */,
  h4: { size: 0.875, height: 1.25 } /* 14px, 20px */,
  h5: { size: 0.75, height: 1 } /* 12px, 16px */,
};

export const fontWeight = {
  regular: 400,
  semibold: 600,
  bold: 700,
};


const fontColor = {
  lightBlue: color.lightBlue,
  lightBlue50: color.lightBlue50,
  darkBlue: color.darkBlue,
  pastelRed: color.pastelRed,
  snow: color.snow,
  yellow: color.yellow,
};

const getColor = (props) => {
  if (props.inverted) return color.white;
  if (props.color) return fontColor[props.color];
  return fontColor.eggplant;
};

const Text = styled.span.attrs((props) => ({
  textStyle: props.textStyle,
  weight: props.weight || 'regular',
  inverted: props.inverted,
  fontStyle: props.fontStyle,
}))`
  /* stylelint-disable-next-line no-extra-semicolons */
  font-weight: ${(props) => (props.weight ? `${fontWeight[props.weight]}` : 'regular')};
  font-size: ${(props) => TextStyle[props.textStyle].size}px;
  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
  font-family: ${(props) => font[TextStyle[props.textStyle].fontType] };
  color: ${(props) => getColor(props)};
  ${(props) => props.fontStyle && `font-style: ${props.fontStyle}`};
  > p {
    color: ${(props) => getColor(props)};
    > strong {
      color: ${(props) => getColor(props)};
    }
  }
`;

export const WideText = styled(Text)`
  letter-spacing: 0.1rem;
`;

export const SubText = styled(Text)`
  opacity: 0.75;
`;

export default Text;
