import styled from 'styled-components';

const FlatContainer = styled.div.attrs((props) => ({
  justifyContent: props.justifyContent || 'flex-start',
  width: props.width,
  marginUnset: props.marginUnset || false,
  alignItems: props.alignItems || 'center',
}))`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  ${(props) => (props.marginUnset ? null : 'margin: 10px 0;')};
  align-items: ${(props) => props.alignItems};
  background: inherit;
  justify-content: ${(props) => props.justifyContent};
  ${(props) => props.width
    && `width: ${props.width};`}
`;

export default FlatContainer;
